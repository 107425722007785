// import moment from 'moment-timezone'

var moment = require('moment-timezone');
moment.locale(localStorage.getItem('language') === null ? 'tr' : localStorage.getItem('language'))
moment.tz.setDefault(moment.tz.guess() === undefined ? "Europe/Istanbul" : moment.tz.guess());

const DateUtil = {
  /**
   * return date as D MMMM YYYY
   * @param date
   * @returns {string}
   */
  getFormattedDate(date) {
    return moment(moment(date)._d).format('D MMMM YYYY')
  },

  /**
   * return date as D MMMM YYYY HH:mm
   * @param date
   * @returns {string}
   */
  getFormattedTime(date) {
    return moment(moment(date)._d).format('Do MMMM YYYY HH:mm')
  },

  getTime(date) {
    return moment(moment(date)._d).format('HH:mm')
  },

  /**
   * return date as YYYY-MM-DD
   * @param date
   * @returns {string}
   */
  getFormattedDate2(date) {
    return moment(moment(date)._d).format('YYYY-MM-DD')
  },

  getCalendarDay(date) {
    return moment(moment(date)._d).calendar()
  },

  now() {
    return moment();
  },

  fromNow(date) {
    return moment(moment(date)._d).fromNow()
  }
}

export default DateUtil
